import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import { Button } from "react-md";

function Hero() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "images/Rectangle 3.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    // <div className=" index__section-1">
    <BackgroundImage
      Tag="section"
      className="index__hero"
      fluid={imageData}
      backgroundColor={`#303d4e`}
    >
      <div className="container index__hero-shadow">
        <div>
          <h1 className="index__hero-header index__hero-header-nowrap">
            Math done{" "}
            <span className="index__hero-underline-parent">
              right<span className="index__hero-underline-child"></span>
            </span>
            .
          </h1>
          <h1 className="index__hero-header">Depth, clarity, and rigor.</h1>
          <div className="index__hero-sub-header-container">
            <h2 className="index__hero-sub-header">
              <span>Built by teachers.</span> <span>Used by millions.</span>
            </h2>
          </div>
        </div>
        <div className="index__hero-buttons">
          <Link to="/teachers-schools">
            <Button
              className="index__hero-button"
              theme="secondary"
              themeType="contained"
            >
              For Teachers&#x200a;/
              <br />
              Schools
            </Button>
          </Link>
          <Link to="/students">
            <Button
              className="index__hero-button"
              theme="secondary"
              themeType="contained"
            >
              For Students
            </Button>
          </Link>
        </div>
      </div>
    </BackgroundImage>
    // </div>
  );
}

export default Hero;
